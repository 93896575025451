import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input/input";
import Button from "../Button";
import info_icon from "../../../images/Info.svg";
import './text-field.scss';

export default forwardRef(function TextField(
  {
    type = "text",
    name,
    error,
    leftIcon,
    rightIcon,
    rightIcon2,
    labelRequired = false,
    autoComplete = "off",
    tooltiptext = "",
    tooltiptext2 = "",
    requiredText,
    rightContent,
    ...props
  }: any & {
    error?: string;
    isFocused?: boolean;
    leftIcon?: string;
    rightIcon?: string;
    rightIcon2?: string;
    labelRequired?: boolean;
    type?: string;
    name: string;
    tooltiptext?: "";
    tooltiptext2?: "";
    requiredText?: string;
    rightContent?: any;
  },
  ref,
) {
  const localRef = useRef<HTMLInputElement>(null);

  const [showPassword, updateShowPassword] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useImperativeHandle(ref, () => ({
    focus: () => localRef.current?.focus(),
  }));

  useImperativeHandle(ref, () => ({
    focus: () => localRef.current?.focus(),
  }));

  const renderInput = () => {
    if (type === "tel") {
      return (
        <PhoneInput
          {...props}
          name={name}
          country="US"
          ref={localRef}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      );
    }
    return (
      <>
        <input
          {...props}
          type={type === "password" ? (showPassword ? "text" : "password") : type}
          name={name}
          autoComplete={autoComplete}
          ref={localRef}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {rightContent ? (
          <>
            {rightContent}
          </>
        ) : null}
      </>
    );
  };

  const renderLabel = () => {
    return (
      props.label && (
        <label htmlFor={name} className="has-label-replacer">
          <span className="label-text">{props.label}</span>
          {labelRequired && 
            <span className={`required-label ${requiredText ? 'with-text' : ''}`}>
              *
              {requiredText ? (
                <span className='required-text'>{requiredText}</span>
              ) : null}
            </span>}
          {tooltiptext && (
            <Button
              className="tooltip-btn"
              transparent={true}
              type="button"
              style={{
                marginLeft: "6px",
              }}
            >
              <img src={info_icon} alt="info-icon" className="img-fluid" />
              <span className="tooltiptext">
                <span className="small">{tooltiptext}</span>
                <span className="small">{tooltiptext2}</span>
              </span>
            </Button>
          )}
        </label>
      )
    );
  };

  const renderError = () => {
    return error ? <p className="error-msg">{error}</p> : null;
  };

  if (!leftIcon)
    return (
      <>
        {props.label && renderLabel()}
        {renderInput()}
        {renderError()}
      </>
    );

  return (
    <>
      <div className="input-field-desc">
        {renderLabel()}
        <div className={`field-wrapper ${rightContent ? 'with-right-text' : ''}`}>
          <img src={leftIcon} alt="left-icon" className="img-fluid left-icon" />
          {renderInput()}
          {rightIcon ? (
            <img
              src={showPassword ? rightIcon2 : rightIcon}
              alt={`${name}-right-icon`}
              className="img-fluid right-icon show"
              onClick={(_e: React.MouseEvent<HTMLElement>) => {
                updateShowPassword(!showPassword);
              }}
            />
          ) : null}
        </div>
      </div>
      {renderError()}
    </>
  );
});
